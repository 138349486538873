<template>
  <div class="duration-wrapper padding-top-layout csv-format-content">
    <div class="padding-left-40">
      <page-title :title="$t('csv_format.label_title')" />
    </div>
    <div class="csv-format">
      <div class="setting-csv-format">
        <div class="setting-csv-format-label mb-4">{{ $t('csv_format.label_change_setting')}}</div>
        <RadioSelect v-model="value" :defaultValue="value" :items="items" />
      </div>
      <input class="submit-btn" type="button" :value="buttonChangeSettingFormat" @click="handleUpdateUserEncoding()" />
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageSavedChange" @submit="dialogNotification = false" :confirmText="$t('csv_format.button_decision')" :titleText="$t('csv_format.title_popup_success')" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import { updateUserEncoding } from '@/api/registerData';
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import RadioSelect from "@/components/commonApp/RadioCustom/index.vue";
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import { getUserInfo } from "@/api/auth";

export default {
  name: 'SettingAutoApproval',
  components: { NotificationPopup, RadioSelect, PageTitle },
  data() {
    return {
      breadcrumb: [],
      items: [
        {
          name: "Shift JIS",
          tooltip: this.$t('csv_format.message_tooltip_shift_jis'),
          id: "SJIS"
        },
        {
          name: "UTF-8",
          tooltip: this.$t('csv_format.message_tooltip_utf8'),
          id: "UTF-8"
        }
      ],
      value: "SJIS",
      dialogNotification: false
    };
  },

  computed: {
    buttonChangeSettingFormat() {
      return this.$t('csv_format.button_back_csv_format');
    },
    messageSavedChange() {
      return this.$t('csv_format.message_saved_changes')
    },
    isProductCsvFormat() {
      return this.$route.name === 'ProductCsvFormat';
    },
  },

  async mounted() {
    if (this.isProductCsvFormat) {
      this.breadcrumb = [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
        {
          text: this.$t('setting.title_setting'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING,
        },
        {
          text: this.$t('setting.button_setting_csv_format'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.CSV_FORMAT,
        },
      ];
    } else {
      this.breadcrumb = [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('setting.title_setting'),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t('setting.button_setting_csv_format'),
          disabled: false,
          href: ROUTES.CSV_FORMAT,
        },
      ];
    }

    await Promise.all([this.getDefaultEncoding(), this.updateBreadCrumb(this.breadcrumb)]);
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    async getDefaultEncoding() {
      try {
        const response = await getUserInfo();
        this.value = response.encoding;
      } catch (err) {
        console.log(err);
      }
    },
    async handleUpdateUserEncoding() {
      try {
        const data = {
          encoding: this.value
        }
        await updateUserEncoding(data)
        this.dialogNotification = true
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.duration-wrapper {
  h1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    margin: 40px 0px 80px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: left;
    color: $monoBlack;
    margin-bottom: 80px;
  }
}
.submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 2px 20px;
  background: $goldMid;
  width: 100%;
  height: 56px;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  color: $monoWhite;
  &:hover {
    /* Gold/Light */

    background: $goldLight;
    box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
      0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
  }
  &:focus-within {
    // border: 2px solid $blueMid;
    padding: 14px 0px 18px;
    color: $monoWhite;
  }
}
.csv-format-content {
  .page-title {
    margin: 40px 0px 80px;
  }
  @media only screen and (min-width: 1024px) {
    .page-title {
      margin: 0 0 80px;
    }
  }
}
.csv-format {
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin: 0 auto;
  .setting-csv-format {
    &-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;
    }
  }
}
@include desktop {
  .duration-wrapper {
    h1 {
      margin: 0 0 80px;
    }
    form {
      width: 600px;
      margin: 0 auto;
    }
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .submit-btn:focus {
    background-color: $goldMid !important;
    opacity: 1;
  }
}
</style>
